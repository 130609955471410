

export const Loader = ({ full = false }) => {
  return (
    <>
      {full ? (
        <div className="w-full h-screen flex justify-center items-center">
          <div className="lds-hourglass" />
        </div>
      ) : (
        <>
          <div className="lds-hourglass" />
        </>
      )}
    </>
  )
}
