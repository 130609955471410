import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import logo from './assets/newLogo.png'
import { Header } from './components/header'
import { Loader } from './components/loader.js'
import { useHttp } from './hooks/http.hook'

import lng from './components/lang.js'




function App() {

  const [params, _] = useSearchParams()
  const [lang, setLang] = useState('ru')
  const [orderStatus, setOrderStatus] = useState(null)
  const [orderMessage, setOrderMessage] = useState("")
  const [orderGame, setOrderGame] = useState("")
  const [inv, setInv] = useState(null)

  const { request, loading, error, clearError } = useHttp()

  useEffect(() => {
    // console.log(error)
    clearError()
  }, [error, clearError])

  useEffect(() => {

    ; (async () => {

      try {
        const data = await request('/api/checkOrder', 'POST', {
          uniquecode: params.get('uniquecode'),
        })
        setOrderStatus(data.success)
        setOrderMessage(data.message)
        setOrderGame(data?.game)
        setInv(data.inv)
        console.log("data", data.message)
      } catch (e) {
        console.log(e)
      }
    })()
  }, [])

  const changeLangHandler = (lng) => {
    setLang(lng)
  }




  console.log("----", params.get('uniquecode'))




  return (

    <div className='flex w-full h-screen justify-center items-center py-6'>
      <div className='w-full mx-4 my-2 flex flex-col gap-6 h-full'>
        <Header
          lang={lang}
          changeLangHandler={changeLangHandler}
          orderGame={orderGame}
        />

        <div className='w-full px-4 flex gap-4 justify-between'>
          <div id='contacts' className='flex flex-col gap-6 max-w-1/4 w-1/4'>


            <a className="w-full flex justify-center"
              //   href="https://fun-balance.com/" target="_blank"
              rel="noopener noreferrer">

              <img src={logo} alt="banner" className='w-full block' />

            </a>




            <div className='flex flex-col text-sm'>
              <span className='text-xl font-semibold mb-1'>{lng.tr(lang, 'Contacts')}:</span>
              <span>{lng.tr(lang, 'Telegram')}: <a className='text-sky-500' href='https://t.me/FunBalance' target='_blank'>https://t.me/FunBalance</a></span>
              <span>E-mail: <a className='text-sky-500' href='mailto:manager@fun-balance.com' target='_blank'>manager@fun-balance.com</a></span>
            </div>

            {/* <div className='flex flex-col text-sm'>
              <span className='text-xl font-semibold mb-1'>{lng.tr(lang, 'Help with activation')}:</span>
               <span>{lng.tr(lang, 'Help on the website')}: <a className='text-sky-500' href='https://turdigi.shop/help' target='_blank'>https://turdigi.shop/help</a></span>
             </div> */}

            <div className='flex flex-col text-sm'>
              <span className='text-xl font-semibold mb-1'>{lng.tr(lang, 'All your orders')}:</span>
              {/* <span>{lng.tr(lang, 'History of orders')}: <span className='text-sky-500 cursor-pointer' onClick={renderAllOrders}>{lng.tr(lang, 'click here')}</span></span> */}
            </div>
          </div>

          <div className='w-full flex items-center justify-center'>

            {orderStatus === null ?
              <Loader />
              :
              <></>
            }


            {orderStatus ?


              <div className=' flex flex-col w-full items-center gap-[20px]'>

                <div className='text-[24px] font-bold text-[#00FF00]'>{orderMessage}</div>


                <div className="flex flex-col lg:flex-row items-center gap-[10px]">
                  <div className="text-[20px] font-bold">
                    Будем крайне благодарны за отзыв
                  </div>
                  <a
                    href={`https://digiseller.market/info/buy.asp?id_i=${inv}&lang=ru-RU`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div
                      className="border-[1px] rounded-xl text-[20px] text-[#fff] font-bold p-2 cursor-pointer flex flex-row justify-center gap-[30px] bg-[#000] uppercase transition-transform duration-700 hover:scale-105"
                    >
                      <div class="heart"></div> Оставить отзыв
                    </div>
                  </a>
                </div>




                <div className='text-[20px] font-bold text-[#000000]'>Уважаемый игрок! Донат поступит в игру в течении 2-5 минут. Иногда бывают задержки до 2 часов, в особо редких случаях до 24 часов. Обычно такое можно наблюдать в момент перегрузки серверов после очередного большого обновления игры.
                </div>

                <div className='text-[18px] text-[#000000]'>
                  Если вам не поступил донат в течении 2 часов, просьба связаться с нами по контактным данным указанным слева или через чат "переписка с продавцом" внутри вашей покупки в магазине.
                </div>



              </div>


              :
              <div className='text-[24px] font-bold text-[#FF0000]'>
                {orderMessage.split('\n').map((item, key) => (
                  <div key={key}>{item}</div>
                ))}
              </div>
            }

          </div>
        </div>

      </div>
    </div>
  )
}

export default App;


