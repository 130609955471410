export default {
  en: {
    "Order id": "Order id",
    "Nominal": "Nominal",
    "Code": "Code",
    "Copy": "Copy",
    "Copied": "Copied",
    "Your order": "Your order",
    "All your orders": "All your orders",
    "History of orders": "History of orders",
    "Date of order": "Date of order",
    "Paid": "Paid",
    "click here": "click here",
    "Your product": "Your product",
    "Info and order fulfillment control": "Info and order fulfillment control",
    "Time of purchase": "Time of purchase",
    "Codes Turkey | top-up card": "Codes Turkey | top-up card",
    "Unique product code": "Unique product code",
    "Seller": "Seller",
    "Contacts": "Contacts",
    "Telegram": "Telegram",
    "Help with activation": "Help with activation",
    "Help on the website": "Help on the website",
    "Need help?": "Need help?",
    "Contact the seller": "Contact the seller",
    "Our other products": "Our other products",
    "Leave a review and get a gift for free": "Leave a review and get a gift for free",
    "Thanks for the purchase": "Thanks for the purchase",
    "Loading...": "Loading...",
    "Can't collect your sum by existing nominals, please contact the seller!": "Can't collect your sum by existing nominals, please contact the seller!",
    "Steam Gift Card: Activation Instructions": "Steam Gift Card: Activation Instructions",
    "1. Log in to your Steam account": "1. Log in to your Steam account",
    "2. Follow the link: ": "2. Follow the link: ",
  },
  ru: {
    "Order id": "Номер заказа",
    "Nominal": "Номинал",
    "Code": "Код",
    "Copy": "Копировать",
    "Copied": "Скопировано",
    "Your order": "Ваш заказ",
    "All your orders": "Все ваши заказы",
    "History of orders": "История заказов",
    "Date of order": "Заказ от",
    "Paid": "Оплачено",
    "click here": "нажми здесь",
    "Your product": "Ваш товар",
    "Info and order fulfillment control": "Инфо и контроль выполнения заказа",
    "Time of purchase": "Время покупки",
    "Codes Turkey | top-up card": "Коды Турция | карта пополнения",
    "Unique product code": "Уникальный код товара",
    "Seller": "Магазин",
    "Contacts": "Контакты",
    "Telegram": "Телеграм",
    "Help with activation": "Помощь в активации",
    "Help on the website": "Помощь на сайте",
    "Need help?": "Возникли проблемы?",
    "Contact the seller": "Свяжитесь с продавцом",
    "Our other products": "Другие наши товары",
    "Leave a review and get a gift for free": "Оставь отзыв и получи подарок бесплатно",
    "Thanks for the purchase": "Спасибо за покупку",
    "Loading...": "Проверяю код...",
    "Can't collect your sum by existing nominals, please contact the seller!": "Не могу собрать необходимую сумму, пожалуйста свяжитесь с продавцом!",
    "Steam Gift Card: Activation Instructions": "Подарочная карта Steam: Инструкция по активации",
    "1. Log in to your Steam account": "1. Войдите в свою учетную запись Steam",
    "2. Follow the link: ": "2. Перейдите по ссылке: ",
  },
  tr: function (lng, text) {
    return this[lng][text] || text
  }
}