import banner from '../assets/liraBanner.jpg'

import GENSHIN from '../assets/GENSHIN.jpg'
import HONKAI from '../assets/HONKAI.jpg'
import ZENLESS from '../assets/ZENLESS.jpg'

export const Header = ({ changeLangHandler = () => {},   orderGame }) => {
	const gameLogo = (game) => {
		switch (game) {
			case 'GENSHIN':
				return GENSHIN
			case 'HONKAI':
				return HONKAI
			case 'ZENLESS':
				return ZENLESS
			default:
				return banner
		}
	}

	return (
		<div className='w-full px-4 py-3 flex flex-col gap-2'>
			<div className='w-full flex gap-1'>
				<span
					className='cursor-pointer text-sky-600'
					onClick={() => {
						changeLangHandler('ru')
					}}
				>
					RUS
				</span>
				<span className=''>|</span>
				<span
					className='cursor-pointer text-sky-600'
					onClick={() => {
						changeLangHandler('en')
					}}
				>
					ENG
				</span>
			</div>
			<img src={gameLogo(orderGame)} alt='banner' className='w-full block' />
		</div>
	)
}
